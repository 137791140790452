.mobilePaginationWrapper {
  bottom: 68px !important;
}

.noteContent {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #272522;
  padding-top: 16px !important;
  padding-bottom: 19px !important;
}

.footer {
  gap: 16px;
}

@media (max-width: 767px) {
  .listWrap {
    padding-bottom: 24px;
  }
}
