.container {
  background: #ffffff;
  border: 1.5px solid #dfdfdf;
  border-radius: 5px;
  margin-bottom: 20px;
}

.fieldContainer {
  padding: 0 12px;
}

.fieldRow {
  border-top: 1px solid #e7e6e4;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.fieldLabel {
  font-weight: 600;
  flex-shrink: 0;
}

.fieldData {
  text-align: right;
}

.statsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  padding: 12px;
  border-top: 1px solid #e7e6e4;
}

.statItem {
  display: flex;
  gap: 12px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.4);
  background-color: #fbfbfb;
  padding: 10px 3px 10px 10px;
}

.grey {
  color: rgba(39, 37, 34, 0.6);
}

.greyIcon rect {
  fill: #f2f2f2;
}

.greyIcon,
.greyIcon path {
  color: rgba(39, 37, 34, 0.6);
  fill: rgba(39, 37, 34, 0.6);
}

.statsValue {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-top: 6px;
}

.color1 {
  color: #493ab1;
}

.color2 {
  color: #00b77a;
}

.color3 {
  color: #0052c9;
}

.color4 {
  color: #cc5c5c;
}

.infoIcon {
  fill: rgba(0, 0, 0, 0.3) !important;
  cursor: pointer;
  width: 12px !important;
  height: 12px !important;
}

.header {
  padding: 16px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: calc(100vw - 32px);
}

.headIcon {
  color: #d9d9d9;
}

.headContent {
  flex-grow: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #493ab1;
}

.disabled {
  opacity: 0.5;
}

.headerRightNode {
  flex-shrink: 0;
}

@media (max-width: 375px) {
  .statsContainer {
    grid-gap: 4px;
    padding: 4px;
  }
}
