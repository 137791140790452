.container {
  background: #ffffff;
  border: 1.5px solid #e7e6e4;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 0 13px 0 12px;
}

.fieldContainer {
  padding: 12.5px 48px 0px 48px;
}

.fieldRow {
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.85);
}

.statsContainer {
  border-top: 1px solid #e7e6e4;
  padding: 15.5px 36px 16px 19.5px;
}

.statItem {
  padding-right: 20px;
  padding-left: 16.5px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #272522;
  display: inline-block;
}

.grey {
  color: rgba(39, 37, 34, 0.6);
}

.statItem + .statItem {
  border-left: 1.5px solid #c4c4c4;
}

.statsValue {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

.color1 {
  color: #493ab1;
}

.color2 {
  color: #00b77a;
}

.color3 {
  color: #0052c9;
}

.color4 {
  color: #cc5c5c;
}

.infoIcon {
  fill: rgba(0, 0, 0, 0.3) !important;
  cursor: pointer;
}

.header {
  padding: 16px 12px 10px 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1.5px solid #e7e6e4;
}

.noBorder .headIcon {
  color: #d9d9d9;
}

.headButtonWrap {
  line-height: 20px !important;
}

.headContentWrap {
  flex-grow: 1;
}

.headContent {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #493ab1 !important;
  max-width: 600px;
  cursor: pointer;
  height: 20px !important;
}

.headerRightNode {
  flex-shrink: 0;
}

.desktopActionWrap {
  display: flex;
  align-items: center;
  gap: 18px;
}

.desktopAction {
  padding: 0 !important;
}
