.container {
  padding: 16px 24px 20px 24px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #272522;
}

.primaryText {
  color: #493ab1;
}

.footer {
  gap: 16px;
}

@media (max-width: 767px) {
  .container {
    padding: 16px 16px 20px 16px;
  }
}
