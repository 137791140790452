.mobilePaginationWrapper {
  bottom: 68px !important;
}

.desktopActionIcon,
.desktopActionIcon path {
  fill: #6d675f !important;
}

.blueButton {
  color: #493ab1 !important;
}

.redButton {
  color: #aa2822 !important;
}

.blackButton {
  color: #272522 !important;
}

.actionIcon,
.blueAction,
.redAction {
  width: 24px !important;
  height: 24px !important;
}

.blueAction,
.blueAction path {
  fill: #493ab1;
}

.actionIcon,
.actionIcon path {
  fill: #272522;
}

.redAction,
.redAction path {
  fill: #aa2822;
}

@media (max-width: 767px) {
  .listWrap {
    padding-bottom: 24px;
  }
  .actionIcon {
    fill: #6d675f !important;
  }
}
